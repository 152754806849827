import { memo, useState } from "react";
import Container from "./Container";
import { getCookies } from "cookies-next";
import { useRouter } from "next/router";
import useSWR from "swr";
import { getProductsByCategoryIdV2 } from "@/services/category/getProductsData";
import { deviceSwitcherKey } from "@/utils/home";
import { hotProductsBreakPoints } from "@/constants/BreakPoints";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "@/components/Helpers/ProductCard";
import Link from "next/link";
import ArrowIcon from "@/components/icons/ArrowIcon";
import NextImage from "../NextImage";
import { validateImgSrc } from "@/utils/global";
import ProductCardSkeleton from "../Helpers/ProductCardSkeleton";

function HotProductsList({ data, isMobile, t }) {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const { locale } = useRouter();
  const [swiper, set_swiper] = useState({});
  const img = data[deviceSwitcherKey("img", isMobile, locale)];
  const { region_id } = getCookies();

  const { data: secData, isLoading } = useSWR(
    `hot-products-list-${data?.id}-${region_id || 1101}-${locale}`,
    () =>
      getProductsByCategoryIdV2(
        undefined,
        data?.id,
        locale,
        "position",
        "ASC",
        region_id || 1101,
        0,
        12
      ),
    {
      revalidateOnFocus: false,
      // refreshInterval: 30 * 60 * 1000,
      errorRetryCount: 3,
      errorRetryInterval: 5000,
    }
  );
  return (
    <Container className="my-10 bg-white rounded-md max-md:my-4">
      <div className="flex flex-col justify-between gap-4 max-lg:gap-0">
        <div className="flex items-center gap-4 max-md:gap-0">
          <h2 className="p-2 px-3 text-3xl font-semibold max-[570px]:text-lg text-blue">
            {data[`title-${locale}`]}
          </h2>
          <Link
            href={`/${data?.url}`}
            className="flex items-center self-end gap-2 mb-2 max-[570px]:gap-1 text-lightblue"
          >
            <span className="text-sm font-semibold max-[570px]:text-xs">
              {locale === "en" ? "Show More" : "مشاهده المزيد"}
            </span>
            <ArrowIcon className="w-2 h-2 mt-0.5 rtl:rotate-180 ltr:rotate-0" />
          </Link>
        </div>
        <div
          className={`py-8 ${
            locale == "ar" ? (img ? "pr-8" : "pr-16") : img ? "pl-8" : "pl-16"
          } p-16 rounded-lg ${
            img
              ? "p-8 grid grid-cols-12 gap-5 min-[700px]:gap-16 min-[700px]:p-16"
              : ""
          } max-[570px]:p-3`}
          style={{ backgroundColor: data["background-color"] }}
        >
          {img ? (
            <Link
              href={`/${data?.url || ""}`}
              className="col-span-4 my-auto max-[570px]:col-span-full"
            >
              <NextImage
                src={validateImgSrc(img)}
                alt={data[`title-${locale}`]}
                className="object-contain w-full h-auto"
                width={500}
                height={500}
              />
            </Link>
          ) : null}
          <div className="col-span-8 my-auto max-[570px]:col-span-full">
            <Swiper
              className="w-full"
              spaceBetween={12}
              onSlideChange={(swiper) => {
                setIsBeginning(swiper.isBeginning);
                setIsEnd(swiper.isEnd);
              }}
              onInit={(swiper) => {
                set_swiper(swiper);
              }}
              dir={locale === "ar" ? "rtl" : "ltr"}
              key={locale}
              breakpoints={hotProductsBreakPoints(!img)}
            >
              {isLoading &&
                [1, 2, 3, 4].map((i) => (
                  <SwiperSlide key={i * 3454}>
                    <ProductCardSkeleton />
                  </SwiperSlide>
                ))}

              {secData?.products?.length && !isLoading
                ? secData?.products?.map((product) => (
                    <SwiperSlide key={product?._source?.sku}>
                      <ProductCard
                        {...{
                          t,
                          product: product?._source,
                          customHeight: "h-[30rem]",
                        }}
                      />
                    </SwiperSlide>
                  ))
                : null}
            </Swiper>

            {secData?.products?.length > 4 && !isMobile ? (
              <div className="min-[1700px]:max-w-[1500px] m-auto relative block">
                {(locale === "ar" ? !isEnd : !isBeginning) && (
                  <button
                    onClick={() =>
                      locale === "ar"
                        ? swiper?.slideNext()
                        : swiper?.slidePrev()
                    }
                    className="absolute z-[99] items-center justify-center bg-white w-7 h-7 flex -translate-y-1/2 rounded-full text-tertiary-600 -top-[200px] -left-12 size-10 max-[991px]:flex"
                  >
                    <ArrowIcon className="text-black rotate-180 size-4" />
                  </button>
                )}
                {(locale === "ar" ? !isBeginning : !isEnd) && (
                  <button
                    onClick={() =>
                      locale === "ar"
                        ? swiper?.slidePrev()
                        : swiper?.slideNext()
                    }
                    className="absolute z-[99] items-center bg-white w-7 h-7 flex justify-center -translate-y-1/2 rounded-full text-tertiary-600 -top-[200px] -right-12 size-10 max-[991px]:flex"
                  >
                    <ArrowIcon className="text-black size-4" />
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default memo(HotProductsList);
